import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head';
import layoutStyles from '../styles/style.module.scss'

const ContributionsPage = () => {
    return (
        <div name='topo'>
            <Head title= 'contributions'/>
            <Layout>
            <h1 className={layoutStyles.t1} name='2022'>2022</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Salobo road, Pará, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Vale/Ambipar.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Yara terminals, Porto Alegre, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Yara/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Nourishment of Central Beach of Balneário Camboriú, Brazil. Coordinators: DTA Engineering & HP Gregorio Consultancy. Client: Prefeitura de Balneário Camboriú.<p className={layoutStyles.parag}>Products: Oil Spill Modelling, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, Santos, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: TRSP.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - C-M-47 Block, Amazonas Mouth Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Rysk Analysis - APA Costa da Algas and RVS Santa Cruz. Coordinators: Ekos Brasil. Client: Renova.<p className={layoutStyles.parag}>Products: Environmental Impacts.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - Santos/São Vicente marine outfallCoordinators: DTA Engineering & HP Gregorio Consultancy. Client: SABESP.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, , .</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2021'>2021</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-J-4 and BM-J-5 Blocks, Jequitinhonha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - FZA-M-57, FZA-M-86, FZA-M-88, FZA-M-125 e FZA-M-127 Blocks, Amazonas Mouth Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Imerys Caulim - Barcarena, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Imerys Caulim.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Water Quality Modelling, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Transshipment activity, Todos os Santos Bay, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Enseada.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Rysk Analysis - REBIO Comboios. Coordinators: Ekos Brasil. Client: Renova.<p className={layoutStyles.parag}>Products: Environmental Impacts.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Granel/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Baixo Iguaçu, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Baixo Iguaçu/Ambipar.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling, Environmental Vulnerability Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2020'>2020</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Enseada Industria Naval, Maragojipe, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Enseada.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Liquiport, Vitória, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Liquiport.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - Rio Doce and Marine costal zoneCoordinators: Ekos Brasil. Client: Renova.<p className={layoutStyles.parag}>Products: Peer Review.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2019'>2019</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Alto de Cabo Frio Block, Campos Basin. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Praia do Jatobá, SE, Brazil. Coordinators: Tetra + & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis, Environmental Monitoring Plan.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, SP, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: RUMO/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Porto Alegre Port, SP, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Yara Brasil Fertilizantes/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Praia do Jatobá, SE, Brazil. Coordinators: Tetra + & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis, Environmental Monitoring Plan.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Itajaí, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Porto de Itajaí.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Rio Grande, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Porto de Rio Grande/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, SP, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Terminal 12-A/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, SP, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Cereal Sul/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Estrada de Ferro Carajás, PA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Vale/Ambipar.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - Amazonas River Mouth, BrazilCoordinators: HP Gregorio Consultancy. Client: Greenpeace.<p className={layoutStyles.parag}>Products: Peer Review.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-BAR-3 and BM-BAR-5 Blocks, Barreirinha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Etapa 3 - FPSO P-70, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Santos Port, SP, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: DP World.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Pipeline OSVAT, SP, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Pipeline OSSP, SP, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Oil Spill Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Pipeline Rota 4, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Rota 4 Participações.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis, Environmental Monitoring Plan.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2018'>2018</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Poly Terminais, SC, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Suatrans/Poly.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco do Sul, SC, Brazil. Coordinators: Salt Environmental & HP Gregorio Consultancy. Client: Golar Power.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Wave Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - QGI Brasil, RS, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Suatrans/Queiroz Galvão.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-BAR-5 Block, Barreirinha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Saco da Capela, SP, Brazil. Coordinators: HP Gregorio Consultancy. Client: Salt Environmental.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Wave Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Praia do Jatobá, SE, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Ponta de Pedras, PA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: TUP Enseada Malato.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Ponta de Pedras, PA, Brazil. Coordinators: Salt Environmental & HP Gregorio Consultancy. Client: TUP Enseada Malato.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Oil Spill Modelling, Sediment Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Praia do Jatobá, SE, Brazil. Coordinators: Tetra + & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis, Environmental Monitoring Plan.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Dourados River, SP, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: UTE Lins/Omega Engenharia.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2017'>2017</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-PEPB-1 and BM-PEPB-3 Blocks, Pernambuco-Paraíba Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-CAL-11 and BM-CAL-12 Blocks, Camamú-Almada Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Fortaleza, CE, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: VLI.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-CAL-10 Block, Camamú-Almada Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - Amazonas River Mouth, BrazilCoordinators: HP Gregorio Consultancy. Client: Greenpeace.<p className={layoutStyles.parag}>Products: Peer Review.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Terminal Ponta da Madeira, MA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Vale.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - EEP Unidade Paragauçu, BA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Enseada Industria Naval.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Praia do Jatobá, SE, Brazil. Coordinators: Salt Environmental & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Morphodynamic Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Praia do Jatobá, SE, Brazil. Coordinators: Salt Environmental & HP Gregorio Consultancy. Client: CELSE.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Santos, SP, Brazil. Coordinators: HP Gregorio Consultancy. Client: AGR Engenharia/CODESP.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Terminal Santa Clara, RS, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Suatrans/Braskem.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Terminal Rio Grande, RS, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Suatrans/Braskem.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-BAR-3 Block, Barreirinha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2016'>2016</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Rio Grande, RS, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Ecosorb/Porto do Rio Grande.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Coast from RJ to CE, Brazil. Coordinators: HP Gregorio Consultancy. Client: Egis Group.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - APA Marinha do Litoral Norte, SP, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Monte HotSpur, BA, Brazil. Coordinators: HP Gregorio Consultancy. Client: Salt Environmental.<p className={layoutStyles.parag}>Products: Hydrodynamic Modelling, Sediment Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Coast from AL to CE, Brazil. Coordinators: HP Gregorio Consultancy. Client: Egis Group.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Guarapari, ES, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Federal University of Espírito Santo.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Rio Grande, RS, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: TEGRAM.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-J-4 and BM-J-5 Blocks, Jequitinhonha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco River, BA, Brazil. Coordinators: HP Gregorio Consultancy. Client: Salt Environmental.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 3, Santos Basin, Brasil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis, Environmental Monitoring Plan.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2015'>2015</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Barcarena, PA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Terminal Fronteira Norte - TERFON.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Sismic 4D, Campos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Etapa 2 - FPSO Cidade de Maricá, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, , Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-CAL-9 Block, Camamú-Almada Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - BM-J-4 and BM-J-5 Blocks, Jequitinhonha Basin, Brazil. Coordinators: Egis Group & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2014'>2014</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - EEP Unidade Paragauçu, BA, Brazil. Coordinators: Milanelli Environmental Consulting & HP Gregorio Consultancy. Client: Enseada Industria Naval.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 1 - FPSO Cidade de Itaguai, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Water Quality Monitoring Plan, SP, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Intervales.<p className={layoutStyles.parag}>Products: Environmental Monitoring Plan.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - UTE Castilho, SP, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Duke Energy.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Etapa 1 - FPSO Cidade de Paraty, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Etapa 1 - FPSO Cidade de São Paulo, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental & HP Gregorio Consultancy. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2013'>2013</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - AGBS, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Emergency Plan - Mexilhão Plataform, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Terminal Aquaviário de Angra dos Reis, RJ, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Transpetro.<p className={layoutStyles.parag}>Products: Scientific Research, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Terminal Guamaré, RN, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Transpetro.<p className={layoutStyles.parag}>Products: Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Lubrificantes e Derivados de Petróleo do Nordeste (LUBNOR), CE, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 2, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis, Environmental Monitoring Plan.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2012'>2012</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - TLD Franco 1, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Environmental Risk Analysis, Environmental Vulnerability Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 1 - FPSO Cidade de Mangaratiba, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 1 - FPSO Cidade de São Paulo, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Etapa 1 - FPSO Cidade de Paraty, Santos Basin, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Impacts, Environmental Risk Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2011'>2011</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Scientific Report - Peregrino Environmental Monitoring and Calcareous Algae Project - Campos Basin, BrazilCoordinators: Laboratory of Coastal Hydrodynamic - University of São Paulo. Client: Statoil.<p className={layoutStyles.parag}>Products: Data Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Terminal Aquaviário de São Sebastião, SP, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Transpetro.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Etanol Pipeline - Paulínia e Ribeirão Preto, SP, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Logum.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Pipeline OCVAP I & II, SP, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - Pipeline Rota Cabiúnas, Campos and Santos Basins, Brazil. Coordinators: Mineral Engineering and Environmental. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Environmental Impacts.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2010'>2010</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - RC12 Exploratory Block, Colombia. Coordinators: Consortium GeoHidroEco. Client: Ecopetrol.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Oil Spill Modelling, Mud Modelling, Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - RC11 Exploratory Block, Colombia. Coordinators: Consortium GeoHidroEco. Client: Ecopetrol.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Oil Spill Modelling, Mud Modelling, Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental Impact Assessment - RC09 Exploratory Block, Colombia. Coordinators: Consortium GeoHidroEco. Client: Ecopetrol.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Oil Spill Modelling, Mud Modelling, Environmental Impacts, Environmental Risk Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Largo de Santa Rita - Santos, SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Santa Rita Terminais Portuários.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Data sampling.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2009'>2009</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Sebastião Channel, SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Fundespa.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - Pro Abrolhos Project, BrazilCoordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Data Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Terminal Guamaré, RN, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Transpetro.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Oil Spill Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco River, MG, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Votorantim Metais Zinco.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling, Data sampling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Guanabara Bay, RJ, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Fundespa.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Sediment Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco River, MG, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Votorantim Metais Zinco.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Pinheiros River and Billings Reservoir, SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Cobrape.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Sediment Modelling.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2008'>2008</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco River, MG, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Votorantim Metais Zinco.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Sediment Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - São Francisco River, MG, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Votorantim Metais Zinco.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Campos Basin, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Devon.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Campos Basin, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Shell.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2007'>2007</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Balbino Beach, CE, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Geasanevita.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria de Paulínea (REPLAN), SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Presidente Getúlio Vargas (REPAR), PR, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Lundolpho Alves (RLAM), BA, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Duque de Caxias (REDUC), RJ, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria de Capuava (RECAP), SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Lubrificantes e Derivados de Petróleo do Nordeste (LUBNOR), CE, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Fábrica de Fertilizantes Nitrogenados (FAFEN), SE, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Isaac Sabbé (REMAN), AM, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Unidade de Negócio de Industrialização do Xisto (SIX), PR, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Presidente Bernardes (RPBC), SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Henrique Lage (REVAP), SP, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
                <li className={layoutStyles.t2}>Project: Environmental/Technical Report - Refinaria Refinaria Gabriel Passos (REGAP), MG, Brazil. Coordinators: Hidromares Environmental Assessment and Oceanography. Client: Petrobras.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Hydrodynamic Modelling, Water Quality Modelling.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2006'>2006</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Scientific Report - The structure of water mass and currents in the Admiralty Bay - AntarcticaCoordinators: Laboratory of Coastal Hydrodynamic - University of São Paulo. Client: National Council for Research and Development - Brazil.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data Analysis.</p></li>
                <li className={layoutStyles.t2}>Project: Scientific Report - The currents in the Admiralty Bay - AntarcticaCoordinators: Laboratory of Coastal Hydrodynamic - University of São Paulo. Client: National Council for Research and Development - Brazil.<p className={layoutStyles.parag}>Products: Environmental Analysis and Diagnosis, Data sampling, Data Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            <h1 className={layoutStyles.t1} name='2004'>2004</h1>
            <nav>
              <ul className={layoutStyles.indList}>
                <li className={layoutStyles.t2}>Project: Scientific Report - Water quality modelling for Admiralty Bay - AntarcticaCoordinators: Laboratory of Coastal Hydrodynamic - University of São Paulo. Client: National Council for Research and Development - Brazil.<p className={layoutStyles.parag}>Products: Data Analysis.</p></li>
              </ul>
            </nav>

            <p><br></br></p>

            </Layout>
        </div>
    )
}

export default ContributionsPage
